<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else>
            <!-- <form @submit.prevent="add_users" autocomplete="off">
                <v-layout row wrap>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_firebase_uid" type="text"
                            :label="$store.getters.lang.data.user_firebase_uid" outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_name" type="text" :label="$store.getters.lang.data.user_name"
                            outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_display_name" type="text"
                            :label="$store.getters.lang.data.user_display_name" outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_gender" type="text"
                            :label="$store.getters.lang.data.user_gender" outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_birth_date" type="date"
                            :label="$store.getters.lang.data.user_birth_date" outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable rounded filled :items="towns" v-model="users.town_id" outlined
                            item-text="town_id" item-value="town_id" :return-object="false"
                            :label="$store.getters.lang.data.town_id">
                        </v-select>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_profile_picture" type="text"
                            :label="$store.getters.lang.data.user_profile_picture" outlined class="mx-1" filled required
                            rounded>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_join_date" type="date"
                            :label="$store.getters.lang.data.user_join_date" outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_review_count" type="number"
                            :label="$store.getters.lang.data.user_review_count" outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_report_count" type="number"
                            :label="$store.getters.lang.data.user_report_count" outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_verified" type="text"
                            :label="$store.getters.lang.data.user_verified" outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable rounded filled :items="user_badges" v-model="users.user_badge_id"
                            outlined item-text="user_badge_id" item-value="user_badge_id" :return-object="false"
                            :label="$store.getters.lang.data.user_badge_id">
                        </v-select>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn rounded color="primary" type="submit" x-large>{{$store.getters.lang.data.add_btn}} users
                        </v-btn>
                    </v-flex>
                </v-layout>
            </form> -->
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card>
                        <v-card-title primary-title>
                            {{$store.getters.lang.data.users}}
                            <v-spacer></v-spacer>
                            <v-text-field autocomplete="off" v-model="search" append-icon="fas fa-search">
                            </v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headers" :search="search" :items="rows" class="elevation-0"
                                item-key="user_id">
                                <template v-slot:[`item.user_id`]="{ item }">
                                    <div>
                                        <v-btn icon color="error" :disabled="$store.state.auth.user.admin_role != 'admin'" class="mx-1" @click="select_users(item)" rounded>
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                        <v-btn :to="'/users-list/'+item.user_id" icon color="secondary" class="mx-1"
                                            rounded>
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                                <template v-slot:[`item.user_gender`]="{ item }">
                                    <div>
                                        <span v-if="item.user_gender == 1" class="pink--text">🤮</span>
                                        <span v-if="item.user_gender ==0">کچ گیان</span>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-dialog v-model="delete_dialog" persistent max-width="290">
            <v-card>
                <v-card-title>
                    {{$store.getters.lang.data.delete_question}}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="elevation-0" @click="delete_dialog = false">
                        {{$store.getters.lang.data.cancel_btn}}
                    </v-btn>
                    <v-btn color="green darken-1" text @click="delete_users(selected_users.user_id)">
                        {{$store.getters.lang.data.yes_btn}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import controller from './../../controller/users'
    export default {
        data() {
            return {
                users: {},
                search: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                selected_users: {},
                delete_dialog: false,
                headers: [

                    


                    // {
                    //     text: this.$store.getters.lang.data.user_firebase_uid,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'user_firebase_uid',
                    // },


                    {
                        text: this.$store.getters.lang.data.user_name,
                        align: 'start',
                        sortable: true,
                        value: 'user_name',
                    },


                    // {
                    //     text: this.$store.getters.lang.data.user_display_name,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'user_display_name',
                    // },


                    {
                        text: this.$store.getters.lang.data.user_gender,
                        align: 'start',
                        sortable: true,
                        value: 'user_gender',
                    },


                    {
                        text: this.$store.getters.lang.data.user_birth_date,
                        align: 'start',
                        sortable: true,
                        value: 'user_birth_date',
                    },


                    {
                        text: this.$store.getters.lang.data.town_name,
                        align: 'start',
                        sortable: true,
                        value: 'town_name',
                    },


                    // {
                    //     text: this.$store.getters.lang.data.user_profile_picture,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'user_profile_picture',
                    // },


                    {
                        text: this.$store.getters.lang.data.user_join_date,
                        align: 'start',
                        sortable: true,
                        value: 'user_join_date',
                    },


                   


                    


                    


                    
                    {
                        text: '#',
                        align: 'start',
                        sortable: true,
                        value: 'user_id',
                    },


                ],
            }
        },
        computed: {
            rows() {
                return this.$store.getters.users
            },

            towns() {
                return this.$store.getters.towns
            },

            user_badges() {
                return this.$store.getters.user_badges
            },


        },
        mounted() {
            this.read_users();
        },
        methods: {
            add_users() {
                controller.add_users(this.users).then(r => {
                    if (r.data) {
                        this.users = {}
                        this.$store.state.users.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'users Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add users',
                            color: 'error'
                        }
                    }
                })
            },
            delete_users(user_id) {
                controller.delete_users(user_id).then(r => {
                    this.delete_dialog = false
                    if (r.data) {
                        this.$store.state.users = this.$store.state.users.filter(f => {
                            return f.user_id != user_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'users Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            read_users() {
                this.loading = true
                controller.get_users().then(r => {
                    // this.rows = r.data.rows
                    this.loading = false
                })
            },
            select_users(i) {
                this.selected_users = i
                this.delete_dialog = true
            },
        },
    }
</script>