
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_users(params)  {
            return await axios.post('users/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_users()  {
            // return await axios.get('users/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_users')
        },
        async delete_users(user_id)  {
            return await axios.post('users/delete.php' , {
                user_id : user_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(user_id)  {
            return await axios.post('users/readOne.php' , {
                user_id : user_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_users(params)  {
            return await axios.post('users/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_users(params)  {
            return await axios.post('users/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
